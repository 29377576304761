import React, { useEffect, useState } from 'react'

import AdminButton from '../../../Forms/AdminButtons'
import { getVaishyaNews } from '../../../service/Services'
import { GatividhiyaCard } from '../../../cards';
import { Domain } from '../../../ApiConfig';

export default function News() {
    const [loading, setLoading] = useState(false);
    const [news, setNews] = useState([])
    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const res = await getVaishyaNews()
                if (res?.success) {
                    setNews(res?.data)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }

        })()
    }, [])

    if (loading) {
        return <p className=' font-bold'>Loading...</p>
    }
    return (
        <div>
            <AdminButton text="News" className=" bg-blue-950 text-white" url="/admin/news" />
            <div className='flex  flex-wrap gap-4 justify-center  my-4'>
                {news.map((gatividhi, index) => {
                    const { message,
                        newsPhoto,
                        entryDatetime,
                        newsHeading, } = gatividhi
                    return <div key={index}><GatividhiyaCard heading={newsHeading} gatividhiDate={entryDatetime} photo={Domain + newsPhoto} text={message} /> </div>
                })}

            </div>

        </div>
    )
}
