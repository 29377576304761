import React, { useEffect, useState } from 'react';
import AdminButton from '../../../Forms/AdminButtons';
import { getgallerys } from '../../../service/Services';
import { GalleryCard } from '../../../cards';
import LoadingModal from '../../../Forms/LoadingModel';

export default function Gallery() {
    const [gallerys, setGallerys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGallerys = async () => {
            try {
                setLoading(true);
                const response = await getgallerys();
                console.log('gallery response:', response);
                setGallerys(response?.data?.gallerys || []);
            } catch (error) {
                console.error('Error fetching gallerys:', error);
                setError('Error fetching gallerys. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchGallerys();
    }, []);

    // if (loading) {
    //     return <div className="text-center mt-8">Loading...</div>;
    // }

    if (error) {
        return <div className="text-center text-red-600 mt-8">{error}</div>;
    }

    return (
        <div>
            <LoadingModal show={loading} text='Loading' />
            <AdminButton text="नई गैलरी" className="bg-blue-950 text-white" url="/admin/addgallery" />
            <div className="w-full flex flex-wrap justify-center">
                {gallerys.map((gallery, index) => (
                    <GalleryCard key={index} heading={gallery.Heading} photos={gallery.Photo} />
                ))}
            </div>
        </div>
    );
}
