
const isValid = value => value !== undefined && value !== null && value !== '';


export const validateFormData = (formData, validationFields) => {
    const missingFields = validationFields.filter(field => {
        const value = formData.get(field);
        return !isValid(value);
    });

    if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
    }
};
