import React, { useEffect, useState } from 'react'
import FormHeading from './FormHeading'
import { HeaderBoarder } from '../Components'
import { useForm } from 'react-hook-form';
import { Input, SelectInput, Textarea } from '../FormsInputes.js';
import Swal from 'sweetalert2';
import { addNewGatividhi, addSuggession, uploadGallery } from '../service/Services.js';
import AdminButton from './AdminButtons.js';
import { showSuccessAlert } from '../Components/ShowAlertCustome.js';
import SpriteGenerator from './spritGenerater.js';



export default function GalleryForm() {
    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm({
        gatividhiPhoto: "",

    });
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)
    const [images, setImages] = useState([]);



    const submit = async (data) => {
        console.log(data)
        try {

            // const requiredFields = ['gatividhiText', 'gatividhiDate', 'gatividhiHeading', 'gatividhiPhoto'];
            // const hasMissingFields = requiredFields.some(field => !data[field]);
            // if (hasMissingFields) {
            //     throw new Error("All Star fields are required");
            // }

            if (images.length > 10) {
                Swal.fire({
                    title: 'Warning',
                    text: "Photos cannot exceed more than 10",
                    icon: 'warning',
                });

                return
            }

            setLoading(true);
            console.log(data)

            const formData = new FormData();
            images.forEach(image => {
                formData.append('photos', image);
            });
            formData.append('heading', data.heading);

            // Call addNews API
            const isNewGallerryUploaded = await uploadGallery(formData);
            // Check if news added successfully
            console.log("isNewGallerryUploaded", isNewGallerryUploaded)
            if (isNewGallerryUploaded?.success) {
                showSuccessAlert(isNewGallerryUploaded?.message, "")
                setImages([])
                reset()
            }
        } catch (error) {
            console.error(error);
            setError(error.message);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    };
    // addSuggession
    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };
    return (
        <>
            <HeaderBoarder heading={"गैलरी"} />
            <div className='w-100  md:px-10 '>


                <form onSubmit={handleSubmit(submit)} className='border-[1px] border-gray-900/10 p-4'>
                    <FormHeading heading={'गैलरी'} />

                    {/* <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5"> */}
                    <div className="sm:col-span-12">
                        <Input
                            label="Program heading / कार्यक्रम शीर्षक"
                            placeholder="Program heading"
                            type="text"
                            required="required"

                            {...register("heading", {
                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                minLength: {
                                    value: 3,
                                    message: "कृपया कम से कम 3 अक्षर भरें"
                                },
                                maxLength: {
                                    value: 100,
                                    message: "कृपया 100 अक्षरों से कम भरें"
                                }
                            })}
                        />
                        {errors.heading && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.heading.message}</p>}
                        {/* </div> */}
                        <SpriteGenerator images={images} setImages={setImages} />

                    </div>
                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                        {!isLoading ? <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button> : <button
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sending...
                        </button>}
                    </div>

                </form>
            </div>
        </>
    )
}
// suggesstions entry id-