import React from 'react'
import { useSelector } from 'react-redux';
import { clientDomain, Domain } from '../../../ApiConfig';
import logo from "../../../assets/login card/logo.png"
import QRCode from "qrcode.react";

export default function SamelanEntry() {
    const currentUser = useSelector(state => state.auth?.user);

    console.log('currentUser', currentUser)
    return (
        <div className=' w-100 flex justify-center'>
            <div className=' card p-4 max-w-[380px]'>
                <div className=' flex justify-between items-center'>
                    <img className=' w-12 h-12' src={logo} loading='lazy' />
                    <h1 className=' text-2xl text-pink-900 font-bold '>अ.भा.वैश्य परिचय सम्मेलन</h1>
                </div>
                <div className=' flex my-2  items-center'>
                    <img
                        className="w-20 h-20 me-2"
                        src={currentUser?.photos?.profilePhoto ? (Domain + currentUser.photos.profilePhoto) : (currentUser?.profilePhoto ? (Domain + currentUser.profilePhoto) : 'fallback-image-url')}
                        alt="Profile"
                        loading="lazy"
                    />
                    <div className=' '>
                        <p className=' text-[1rem] font-bold text-gray-600'>ID No.-{currentUser?.matrimonyCode || currentUser?.membershipCode}</p>

                        <p className=' text-[1.2rem] font-bold text-black'>{currentUser?.personalInformation?.name || currentUser?.name}</p>
                        <p className=' text-[1.2rem] font-bold text-orange-600'>{
                            currentUser?.city?.name || currentUser?.personalInformation?.currentCity?.cityName}</p>
                    </div>
                </div>
                <div className=' text-center flex flex-col items-center'>
                    <p className=' text-pink-800 text-[1.5rem] font-bold '>{currentUser?.samaj}({currentUser?.gotra || currentUser?.personalInformation?.gotra})</p>
                    <QRCode
                        size={250}
                        level='L'
                        value={JSON.stringify({
                            matrimonyCode: currentUser?.matrimonyCode || currentUser?.membershipCode,
                            name: currentUser?.personalInformation?.name || currentUser?.name,
                            city: currentUser?.city?.name || currentUser?.personalInformation?.currentCity?.cityNam,
                            gotra: currentUser?.gotra || currentUser?.personalInformation?.gotra,
                            profilePhoto: currentUser?.photos?.profilePhoto ? (Domain + currentUser.photos.profilePhoto) : (currentUser?.profilePhoto ? (Domain + currentUser.profilePhoto) : 'fallback-image-url'),

                        })}
                        viewBox={`0 0 256 256`}
                    />
                    {/* JSON.stringify(currentUser) */}
                    {/* <img className=' ' src={Domain + Qr} loading='lazy' /> */}
                    <small>Scan this QR code for entry</small>
                </div>
            </div>
        </div>
    )
}
