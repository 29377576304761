import logo from './logo.svg';
import './App.css';
import Route from "./Pages/Route"
import { Provider, useDispatch } from "react-redux"
import store from './store/store';
import { createContext, useEffect, useLayoutEffect, useState } from 'react';
import ScrollToTop from './Layouts/ScrollToTOp';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loginUserByJwt } from './Redux/authSlicer';
import LoadingModal from './Forms/LoadingModel';

const LoginContext = createContext()
function App() {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const { isAuthenticated,setIsAuthenticated,  clearData } = useSessionStorage('myData');
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = ''; // This line may not work on modern Hashs due to security restrictions
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // window.history.pushState(null, null, window.location.href);
  // window.onpopstate = function (event) {
  //   window.history.go(1);
  // };



  useEffect(() => {
    const checkUserIsLogin = async () => {
      try {
        setLoading(true);
        const response = await dispatch(loginUserByJwt());
        if (response.error) {
          navigate('/');
        }
        if (!response.payload?.success) {
          console.warn("Login request unsuccessful:", response.payload?.message || "Unknown error");
          return;
        }

        // navigate('/dashboard');
        console.log("response.user?.userType", response.payload?.data?.userType)
        const userType = response.payload?.data?.userType;

        console.log("userType", userType)

        // Navigate based on userType
        switch (userType) {
          case 1:
            navigate('/dashboard');
            return;
          case 2:
            navigate('/dashboard');
            return;
          case 3:
            navigate('/doctor');
            return;
          case 4:
            navigate('/dashboard');
            return;
          case 5:
            navigate('/samelan');
            return;
          case 6:
            navigate('/manufacturer');
            return;
          case 7:
            navigate('/retailer');
            return;
          default:
            navigate('/dashboard'); // Fallback for any other user types
            return;
        }
        // navigate('/dashboard');
      } catch (error) {
        console.error("Failed to verify login:", error);
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    // Check if the current route is NOT /matrimonyQr/:matCode
    // console.log("location.pathname", location.pathname)
    if (!location.pathname.startsWith('/matrimonyQr/')) {
      checkUserIsLogin();
    }
  }, []);


  if (isLoading) {
    return <LoadingModal show={isLoading} text={'Loading'} />
  }

  return (
    // <LoginContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
    <>
      <div className="App  ">
        <Route />
      </div>
      <ScrollToTop />
    </>

    // </LoginContext.Provider>
  );
}
export default App;

export {
  LoginContext
}
