import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const downloadPDF = (input, intrestReport) => {

    // const input = document.getElementById(id)
    const pdf = new jsPDF('p', 'pt', 'a4');
    const pageWidth = pdf.internal.pageSize.width;
    let pageHeight = pdf.internal.pageSize.height;

    // Convert input to a single canvas with correct dimensions
    html2canvas(input, { scale: 2 })
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            let imgWidth = pageWidth;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;
            let position = 0;
            // Add first page
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

            // Calculate remaining height to add more pages
            let remainingHeight = imgHeight - pageHeight;

            while (remainingHeight > 0) {
                pdf.addPage();
                position -= pageHeight;
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                remainingHeight -= pageHeight;
            }

            // Save the PDF with the given name
            pdf.save(`${intrestReport}.pdf`);
        })
        .catch((err) => {
            console.error('Error generating PDF: ', err);
        });
};
