import React, { useState } from 'react'
import { calculateAge, MatrimonyVeiw, MatrimonyViewModel } from '../Components/reshtey';
import { Domain } from '../ApiConfig';
import femaleLogo from "../assets/final cards for mayur/female matromony/femalematriwomenimage.png"
import maleLoga from "../assets/final cards for mayur/male matrimony/male image.png"
import { ShowIntrest } from '../service/Services';

export default function VivahGallery({ candidat, setReciverId }) {

    const [isModalVisible, setModalVisible] = useState(false);

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };
    const { personalInformation, matrimonyCode, occupationDetails, kundliDetails, photos, membershipID } = candidat
    const { currentCity,
        currentMaritalStatus,
        gotra,
        name
        ,
        heightFeet,
        heightInch,
        gender
    } = personalInformation;
    const { occupation } = occupationDetails
    const { birthDateTime } = kundliDetails
    const { profilePhoto } = photos

    return (
        <>

            <MatrimonyViewModel
                isVisible={isModalVisible}
                onClose={closeModal}
                title="Matrimony Profile"
                width="100px"
                height="100px"
            >

                <div className=' w-full flex justify-center items-center'>
                    <button onClick={() => setModalVisible(false)} className=' w-20 rounded-md  font-20  bg-yellow-500 text-black' >Close</button>
                </div>
                {/* <p>This is a customizable modal.</p>
                <p>You can put any content you like here.</p> */}
                <MatrimonyVeiw bioData={candidat} closeBtn={closeModal} />

                <div className=' w-full flex justify-center items-center mt-4'>
                    <button onClick={() => setModalVisible(false)} className=' w-20 rounded-md  font-20  bg-yellow-500 text-white' >Close</button>
                </div>
            </MatrimonyViewModel>


            <div className='w-[95vw] md:w-[350px] xl:w-[400px] shadow-2xl shadow-all-sides  rounded-3xl  p-3 h-[100%]  bg-white flex flex-col justify-start'>
                <div className='flex justify-center '>
                    <p className='bg-blue-900 w-100 px-4 xl:px-5 rounded-xl py-[0.2rem] xl:py-1 text-[1rem]  xl:text-[1.2rem] text-[#FFF411] font-semibold'>Profile ID :  {matrimonyCode}</p>
                </div>
                <div className={` flex mt-2 rounded-t-xl   justify-center text-xl xl:text-[16px] font-bold items-center text-white h-[2rem] xl:h-[2.5rem] w-100 ${(gender == 'Male') ? "metrimony-card-bg-male-head" : "bg-[#EC268F]"}  `} >
                    {name}
                </div>
                <div className={`rounded-b-2xl   relative w-full ${(gender == 'Male') ? "metrimony-card-bg-male-body" : "metrimony-card-bg"}    flex flex-col border-dashed border-gray-500  border-b-2  border-e-2 border-s-2`}>
                    <div className='w-full flex justify-center mt-[-1rem] h-[100%]  xl:mt-[0rem] xl:pb-3'>
                        <div className='  w-[30%]    h-full flex items-center '>
                            {/* <img className=' w-28 h-28 xl:w-36 xl:h-36 object-cover' src={gender == 'Male' ? maleLoga : femaleLogo} /> */}
                            <img
                                className='object-cover'
                                src={`${Domain}${profilePhoto}`}
                                loading='lazy'
                            />
                        </div>

                        <div className="  text-[#FFF411] flex-col text-[12px] leading-[16px] xl:leading-[20px] xl:text-[16px] justify-center w-[100%] py-6  md:py-6 xl:py-0   ">
                            <div className=' h-[100%] ms-5 text-[10px] font-bold   xl:ms-7  xl:pt-3'>
                                <div className="flex flex-row justify-between ">
                                    <p className=" w-20 xl:w-40">गोत्र</p>
                                    <p className="w-4/5 metrimony-card-colone">{gotra} </p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">उम्र</p>
                                    <p className="w-4/5 metrimony-card-colone"> {calculateAge(birthDateTime)} वर्ष</p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">लंबाई</p>
                                    <p className="w-4/5 metrimony-card-colone"> {heightFeet + '.' + heightInch} feet</p>

                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">वर्त्तमान शहर</p>
                                    <p className=" w-4/5 metrimony-card-colone"> {currentCity?.cityName}</p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">व्यवसाय</p>
                                    <p className="w-4/5  metrimony-card-colone"> {occupation}</p>
                                </div>
                                {/* <div className="flex flex-row justify-between">
                                    <p className="w-full">पिताजी का व्यवसाय</p>
                                    <p className=" w-4/5 metrimony-card-colone"> {fatherName}</p>
                                </div> */}
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">वैवाहिक स्थिति</p>
                                    <p className="w-4/5 metrimony-card-colone"> {currentMaritalStatus}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className=' flex justify-center items-center mt-2 w-full absolute top-[85%]'>
                    <img className=' p-1 metrimony-card-bg-bottom shadow-2xl rounded-xl w-16 h-16 absolute top-[-0.5rem] left-[2rem]  md:left-[1.5rem]   xl:left-[2rem]' src={Qr ? `${Domain + Qr}` : QR} />
                    <div className=' metrimony-card-bg-bottom flex p-2 ps-10  gap-2 justify-end rounded-2xl h-100'>
                        <img className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? intrestedMale : intrested} />
                        <img className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? callMale : call} />
                        <img className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? chatMale : chat} />
                        <img onClick={openModal} className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? viweMale : viwe} />
                    </div>
                </div> */}
                </div>
                <div className=' flex justify-evenly font-bold mt-2'>


                    {/* <button onClick={openModal} className=' bg-blue-900 text-sm cursor-pointer text-white py-1 px-5 rounded-lg'>View Profile </button> */}

                    <button onClick={() => setReciverId(membershipID)} className=' bg-blue-900 text-sm cursor-pointer text-white py-1 px-5 rounded-lg'>Show Interest</button>
                </div>
            </div>
        </>
    )
}
