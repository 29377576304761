import React, { useState, useEffect } from 'react';

import { samelanEntryReport } from '../../service/Services';
import LoadingModal from '../../Forms/LoadingModel';
import { Pagination } from './userManagement';
import { Domain } from '../../ApiConfig';


export default function SamelanEntryReport() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    const limit = 10; // Number of entries per page

    useEffect(() => {
        // Fetch the data when the component mounts or when the page changes
        fetchData(currentPage, limit);
    }, [currentPage]);

    const fetchData = async (page, limit) => {
        setLoading(true);
        try {
            const response = await samelanEntryReport(page, limit);
            setData(response.data); // assuming response.data contains the fetched data
            setTotalPages(response.pagination.totalPages); // assuming response.pagination has totalPages
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="container mx-auto">
            <h1 className="text-2xl font-bold mb-4">Samelan Entry Report</h1>
            <LoadingModal show={loading} text={'Loading...'} />


            <div className="overflow-x-auto">
                {/* <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden border">
                    <thead>
                        <tr className="w-fulL text-sm bg-blue-950 text-white text-left">
                            <th className="py-1   px-2 text-left border-2">क्रमांक</th>
                            <th className="py-1  px-2 text-left border-2">नाम</th>
                            <th className="py-1  px-2 text-left border-2">विवाह कोड</th>


                            <th className="py-1 px-2 text-left border-2">Mobile No</th>
                            <th className="py-1 px-2 text-left border-2">Addres</th>
                            <th className="py-1 px-2 text-left border-2">City</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map((intrestReports, index) => {
                            const { personalInformation,
                                photos, matrimonyCode, contactDetails
                            } = intrestReports;

                            return (
                                <tr key={index} className="odd:bg-white text-sm even:bg-gray-50">
                                    <td className="py-1 px-2 border-2 ">{index + 1}</td>
                                    <td className="py-1 px-2 border-2 flex min-w-56">
                                        <img className=' w-10 h-10 rounded-full me-8' src={Domain + photos?.profilePhoto} />
                                        <div className=' mt-auto'>
                                            <h2 className=' text-black font-bold'>{personalInformation?.name}</h2>
                                            <small>{personalInformation.gotra}</small>
                                        </div>
                                    </td>
                                    <td className="py-1 px-2 border-2">{matrimonyCode}</td>
                                    <td className="py-1 px-2 border-2">{contactDetails?.mobileNo}</td>
                                    <td className="py-1 px-2 border-2">{personalInformation?.currentAddress}</td>
                                    <td className="py-1 px-2 border-2">{personalInformation?.currentCity?.cityName}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table> */}




                <table className="min-w-full border-collapse">
                    <thead>
                        <tr className="w-fulL text-sm bg-blue-950 text-white text-left">
                            <th className="py-1   px-2 text-left border-2">क्रमांक</th>
                            <th className="py-1  px-2 text-left border-2">नाम</th>
                            <th className="py-1  px-2 text-left border-2">समाज</th>
                            <th className="py-1  px-2 text-left border-2">विवाह कोड</th>
                            <th className="py-1  px-2 text-left border-2">व्यक्ति की संख्या</th>
                            <th className="py-1  px-2 text-left border-2">प्रविष्टि तिथि समय</th>
                            <th className="py-1 px-2 text-left border-2">मोबाइल नंबर</th>
                            <th className="py-1 px-2 text-left border-2">पता</th>
                            <th className="py-1 px-2 text-left border-2">शहर</th>
                        </tr>
                    </thead>


                    <tbody>
                        {data.length === 0 ? (
                            <tr>
                                <td colSpan="7" className="text-center py-4">
                                    No entries found.
                                </td>
                            </tr>
                        ) : (
                            data.map((entry, index) => {
                                const { memberName, matrimonyCode, mobileNo, currentAddress, samaj, gotra
                                    , currentCity, noOfPerson, entryDatetime, profilePhoto } = entry
                                return <tr key={index} className="odd:bg-white text-sm even:bg-gray-50">
                                    <td className="py-1 px-2 border-2 ">{index + 1}</td>
                                    <td className="py-1 px-2 border-2 flex min-w-56">
                                        <img className=' w-10 h-10 rounded-full me-8' src={Domain + profilePhoto} />
                                        <div className=' mt-auto'>
                                            <h2 className=' text-black font-bold'>{memberName}</h2>
                                            <small>{gotra}</small>
                                        </div>
                                    </td>
                                    <td className="py-1 px-2 border-2">{samaj}</td>
                                    <td className="py-1 px-2 border-2">{matrimonyCode}</td>
                                    <td className="py-1 px-2 border-2">{noOfPerson}</td>
                                    <td className="py-1 px-2 border-2">
                                        {new Date(entry.entryDatetime).toLocaleString()}
                                    </td>
                                    <td className="py-1 px-2 border-2">{mobileNo}</td>
                                    <td className="py-1 px-2 border-2">{currentAddress}</td>
                                    <td className="py-1 px-2 border-2">{currentCity?.cityName}</td>
                                </tr>
                            })
                        )}
                    </tbody>
                </table>
            </div>


            {/* Pagination Controls */}
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
}
