import React, { useId } from "react";

const CheackBox = ({ label, type = "text", className = "", checked, ...props }, ref) => {
    const id = useId();

    // console.log("props")

    return (
        <div className="w-full flex flex-row-reverse justify-center items-center">
            {label && (
                <label htmlFor={id} className="block capitalize  text-sm font-extrabold leading-6 text-gray-900">
                    {label}
                </label>
            )}
            <div className="mt-1">
                <input
                    type={type}
                    ref={ref}
                    id={id}
                    checked={checked}
                    className={`${className} me-2`}
                    {...props}
                />
            </div>
        </div>
    );
};

export default React.forwardRef(CheackBox);


