

import React, { useEffect, useState } from 'react';
import { Domain } from '../ApiConfig';

const GatividhiCard = ({ heading, photo, text, gatividhiDate, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
        setIsOpen(!isOpen);
    };
    // console.log(achivement)
    return (
        <>
            <ViwePopup isOpen={isOpen} setIsOpen={setIsOpen} gatividhi={{ heading: heading, photo: photo, text: text, gatividhiDate: gatividhiDate }} />

            <div className='h-100 m-2 min-w-[330px] rounded-lg    border-r-5 border   shadow-all-sides bg-white text-black  ' >
                <div className={`gatividhi-card-heading rounded-lg    mt-[-0.5px] px-4 py-1 text-center text-white font-bold `}>
                    {heading}
                </div>
                <div className="p-2 flex justify-center flex-col ">
                    <div className="  ">
                        <div className="flex min-w-0 gap-x-4 items-center ">
                            <img className="h-[3.5rem] ring-2 ring-orange-400 w-[3.5rem]  flex-none rounded-full " src={photo?.replace(" ", "%20")} alt="" loading="lazy" />

                            <div className="min-w-0 flex-auto font-extrabold leading-6 ">
                                <p className="text-[15px] flex  font-bold tracking-tight text-gray-600">
                                    <span className=' w-[60px] me-2 flex justify-between'>तिथि  <span>: </span> </span>
                                    {new Date(gatividhiDate).toDateString()}
                                </p>
                                {/* <p className="text-[15px] flex text-start  font-bold tracking-tight text-gray-600">
                                    <span className=' w-[60px] me-2 flex justify-between'>उपलब्धि <span >:</span></span>
                                    {achivement.achievementHeading}
                                </p> */}
                            </div>
                        </div>

                        <div className=' w-full flex justify-center'>
                            <button onClick={togglePopup} className=' px-4 mt-2 rounded-lg cursor-pointer h-100 bg-orange-600 text-white '> View Details </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
};


const ViwePopup = ({ isOpen, setIsOpen, gatividhi, }) => {
    // console.log(popupData)
    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Add achivement listener when component mounts
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // Prevent scrolling on the background page
        }

        // Remove achivement listener when component unmounts
        return () => {
            document.body.style.overflow = 'visible'; // Re-enable scrolling on the background page
        };
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <div className="fixed top-0 left-0 flex h-[100%] py-10  overflow-auto  items-center px-2 justify-center w-full  bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded shadow-2xl mt-36 w-full  md:w-96  overflow-y-auto">
                        <div className='  min-w-[330px] h-[100%]     border-r-5 border   shadow-2xl bg-white text-black  ' >
                            <div className={`gatividhi-card-heading p-2     text-center text-white font-bold `}>
                                {gatividhi?.heading}
                            </div>
                            <div className="p-2 flex w-full items-center  justify-center flex-col ">

                                <img className=" w-14 h-14 ring-2 ring-orange-400    flex-none rounded-full " src={gatividhi?.photo?.replace(" ", "%20")} alt="" loading="lazy" />

                                <div className="  gap-x-4  ">
                                    {/* <div className='border-2 p-[0.5px] mt-3 border-orange-500 rounded-full  '> */}
                                    {/* </div> */}
                                    <div className="min-w-0  font-extrabold leading-6 ">
                                        {/* <p className="text-[17px] ms-6 font-extrabold  " >  </p> */}
                                        <p className="text-[15px] flex  font-bold tracking-tight text-gray-600">
                                            <span className=' w-[65px] me-2 flex justify-between'>तिथि  <span className=' ms-2'>: </span> </span>
                                            {new Date(gatividhi?.gatividhiDate).toDateString()}
                                        </p>
                                        {/* <p className="text-[15px] flex text-start  font-bold tracking-tight text-gray-600">
                                                <span className=' w-[65px] me-2 flex justify-between'>Program <span >:</span></span>
                                                {gatividhi?.text}
                                            </p> */}


                                        <p className="text-[15px]  flex text-start  font-bold tracking-tight text-gray-600">
                                            <span className='  me-4 flex justify-between'>जानकारी <span className=' ms-2' >:</span></span>
                                            {gatividhi?.text}
                                        </p>

                                        {/* <p className="text-[15px] flex text-start  font-bold tracking-tight text-gray-600">
                                                <span className=' w-[75px] me-2 flex justify-between'>स्थान <span className=' ms-2' >:</span></span>
                                                {achivement.placeLocation}
                                            </p> */}
                                        {/* <p className="text-[13px] flex  font-bold tracking-tight text-gray-600"><span className=' w-[50px] me-2 flex justify-between'> व्यवसाय <span>: </span> </span> बिजनेस </p> */}
                                        {/* <div className=' w-full justify-around flex mt-10'>
                                                <img className=" w-14 h-14 ring-2     flex-none  " src={`${Domain + achivement.photo1.replace(" ", "%20")}`} alt="" loading="lazy" />

                                                <img className=" w-14 h-14 ring-2     flex-none " src={`${Domain + achivement.photo2.replace(" ", "%20")}`} alt="" loading="lazy" />


                                            </div> */}
                                    </div>


                                </div>


                                {/* <div className='flex justify-center bg-gray-200 items-end gap-3 mt-4'>
                <p className='flex flex-col items-center cursor-pointer ' onClick={() => window.location.href = `tel:${9827033232}`}> <img className='h-8' src={callporwal} alt="Call" loading="lazy" /> <span className='text-[12px] leading-7 font-bold tracking-tight text-gray-900'>Call</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `whatsapp://send?phone=${9827033232}`}><img className='h-8' src={chatporwal} alt="Chat" loading="lazy" /> <span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'>Chat</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `sms:${9827033232}`}> <img className='h-8' src={messageporwal} alt="Message" loading="lazy" /><span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'> Message</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={togglePopup}> <img className='h-8' src={viewporwal} alt="View" loading="lazy" /><span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'> View</span></p>
            </div> */}
                                {/* Online indicator */}
                                {/* <div className="h-3 w-3 bg-green-400 rounded-full"></div> */}



                            </div>
                        </div>

                        <div className="w-full flex justify-center mt-auto"> {/* mt-auto will push the button to the bottom */}
                            <button
                                className="mt-4 bg-[#172553] hover:bg-blue-700 text-white font-bold py-2 px-10 rounded-full"
                                onClick={togglePopup}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>

            )}

        </>
    );
};

export default GatividhiCard;



