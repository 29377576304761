import React, { lazy, useState } from 'react'

import femaleLogo from "../assets/final cards for mayur/female matromony/femalematriwomenimage.png"

import maleLoga from "../assets/final cards for mayur/male matrimony/male image.png"
import QR from "../assets/final cards for mayur/female matromony/femalmatriqrcode.png"
import intrested from "../assets/final cards for mayur/female matromony/femalematriinterestedbutton.png"
import call from "../assets/final cards for mayur/female matromony/femalematricallbutton.png"
import chat from "../assets/final cards for mayur/female matromony/femalematrichatbutton.png"
import viwe from "../assets/final cards for mayur/female matromony/femalematriviewprofile.png"
import report from "../assets/final cards for mayur/female matromony/femalematrireportprofile.png"

import intrestedMale from "../assets/final cards for mayur/male matrimony/interestedbutton.png"
import callMale from "../assets/final cards for mayur/male matrimony/callbutton.png"
import chatMale from "../assets/final cards for mayur/male matrimony/chatbutton.png"
import viweMale from "../assets/final cards for mayur/male matrimony/viewprofilebutton.png"

import photoFrem from '../assets/matrimonyThims/photofframe.png'
import firstPage from '../assets/matrimonyThims/firtpageblank.png'
import { Domain } from '../ApiConfig'
import { FormHeading } from '../Forms'

export default function Reshtey({ rishtey }) {
    const [isModalVisible, setModalVisible] = useState(false);

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const { Qr, contactDetails,
        divorceDetails,
        educationQualification
        , extraInformation,
        familyDetail,

        kundliDetails,
        mamaDetails,

        marriedStatus,
        matrimonyCode,

        occupationDetails,
        othersDetails,
        personalInformation,
        photos,
        samajReferenceDetails

    } = rishtey;

    const { bloodGroup, currentAddress, currentCity, currentCountry, currentMaritalStatus, currentState, gender,
        heightFeet, heightInch
        , permanentCity,
        gotra

    } = personalInformation

    const { birthDateTime } = kundliDetails
    // : 
    // {birthDateTime: "2024-05-01T11:02:00.000Z", birthPlace: "shendhwa", rashiByDateOfBirth: "मेष",…}
    const {
        fatherName,
        motherName
    } = familyDetail

    return (
        <>
            <MatrimonyViewModel
                isVisible={isModalVisible}
                onClose={closeModal}
                title="Matrimony Profile"
                width="100px"
                height="100px"
            >

                <div className=' w-full flex justify-center items-center'>
                    <button onClick={() => setModalVisible(false)} className=' w-20 rounded-md  font-20  bg-yellow-500 text-black' >Close</button>
                </div>
                {/* <p>This is a customizable modal.</p>
                <p>You can put any content you like here.</p> */}
                <MatrimonyVeiw bioData={rishtey} closeBtn={closeModal} />

                <div className=' w-full flex justify-center items-center mt-4'>
                    <button onClick={() => setModalVisible(false)} className=' w-20 rounded-md  font-20  bg-yellow-500 text-white' >Close</button>
                </div>
            </MatrimonyViewModel>
            <div className='w-[95vw] md:w-[350px] xl:w-[400px] shadow-2xl shadow-all-sides  rounded-3xl  ps-9 p-3 h-[100%]  bg-white flex flex-col justify-start'>
                <div className='flex justify-center '>
                    <p className='bg-blue-900 w-100 px-4 xl:px-5 rounded-xl py-[0.2rem] xl:py-1 text-[1rem]  xl:text-[1.2rem] text-[#FFF411] font-semibold'>Profile ID :  {matrimonyCode}</p>
                </div>
                <div className={`relative flex mt-2 rounded-t-xl   justify-center text-xl xl:text-[16px] font-bold items-center text-white h-[2rem] xl:h-[2.5rem] w-100 ${(gender == 'Male') ? "metrimony-card-bg-male-head" : "bg-[#EC268F]"}  `} >
                    {personalInformation?.name}

                    <img className=' shadow-2xl w-14 h-14 xl:w-16 xl:h-16 ring-[#FFF411] ring-2 absolute z-10 left-[-1.8rem]  xl:left-[-2rem] rounded-full' src={`${Domain + photos?.profilePhoto}`} />

                </div>

                <div className={`rounded-b-2xl   relative w-full ${(gender == 'Male') ? "metrimony-card-bg-male-body" : "metrimony-card-bg"}    flex flex-col border-dashed border-gray-500  border-b-2  border-e-2 border-s-2`}>
                    <div className='w-full flex justify-center mt-[-1rem] h-[100%]  xl:mt-[0rem] xl:pb-3'>
                        <div className='  w-[20%] mt-5 xl:ms-2  xl:mt-2 flex justify-center items-center '>
                            <img className=' w-28 h-28 xl:w-36 xl:h-36 object-cover' src={gender == 'Male' ? maleLoga : femaleLogo} />
                        </div>

                        <div className="  text-[#FFF411] flex-col text-[12px] leading-[16px] xl:leading-[20px] xl:text-[16px] justify-center w-[100%]   ">
                            <div className=' h-[100%] ms-5 text-[10px] font-bold   xl:ms-7 py-6 xl:pt-3'>
                                <div className="flex flex-row justify-between ">
                                    <p className=" w-20 xl:w-40">गोत्र</p>
                                    <p className="w-4/5 metrimony-card-colone">{gotra} </p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">उम्र</p>
                                    <p className="w-4/5 metrimony-card-colone"> {calculateAge(birthDateTime)} वर्ष</p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">लंबाई</p>
                                    <p className="w-4/5 metrimony-card-colone"> {heightFeet + '.' + heightInch} feet</p>

                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">वर्त्तमान शहर</p>
                                    <p className=" w-4/5 metrimony-card-colone"> {currentCity?.cityName}</p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">व्यवसाय</p>
                                    <p className="w-4/5  metrimony-card-colone"> {occupationDetails?.occupation}</p>
                                </div>
                                {/* <div className="flex flex-row justify-between">
                                    <p className="w-full">पिताजी का व्यवसाय</p>
                                    <p className=" w-4/5 metrimony-card-colone"> {fatherName}</p>
                                </div> */}
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">वैवाहिक स्थिति</p>
                                    <p className="w-4/5 metrimony-card-colone"> {marriedStatus}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className=' flex justify-center items-center mt-2 w-full absolute top-[85%]'>
                        <img className=' p-1 metrimony-card-bg-bottom shadow-2xl rounded-xl w-16 h-16 absolute top-[-0.5rem] left-[2rem]  md:left-[1.5rem]   xl:left-[2rem]' src={Qr ? `${Domain + Qr}` : QR} />
                        <div className=' metrimony-card-bg-bottom flex p-2 ps-10  gap-2 justify-end rounded-2xl h-100'>
                            <img className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? intrestedMale : intrested} />
                            <img className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? callMale : call} />
                            <img className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? chatMale : chat} />
                            <img onClick={openModal} className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? viweMale : viwe} />
                        </div>
                    </div>
                </div>
                <div className=' flex justify-end mt-7   xl:mt-2'>
                    <img className=' bg-slate-400 rounded-sm w-16 h-4 xl:w-16 xl:h-5 ' src={report} />
                </div>
            </div>
        </>
    )
}
export function calculateAge(birthDateTime) {

    const birthDate = new Date(birthDateTime);
    const currentDate = new Date();

    let years = currentDate.getFullYear() - birthDate.getFullYear();
    let months = currentDate.getMonth() - birthDate.getMonth();

    if (months < 0) {
        years--;
        months += 12;
    }

    return `${years}.${months}`;
}


export const MatrimonyViewModel = ({ isVisible, onClose, title, children, width, height }) => {
    if (!isVisible) return null;

    return (
        <div
            className="fixed inset-0   flex items-center justify-center bg-black bg-opacity-50 z-50 "
            onClick={onClose}
        >
            <div
                className=" bg-pink-900 rounded-lg shadow-lg w-screen h-[100%] overflow-y-scroll "
                // style={{ width, height }}
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-xl font-semibold">{title}</h2>
                    <button
                        className="text-2xl font-bold text-gray-500 hover:text-gray-700"
                        onClick={onClose}
                    >
                        ×
                    </button>
                </div>
                <div className="p-4">
                    {children}
                </div>
            </div>
        </div>
    );
};

export const MatrimonyVeiw = ({ bioData, closeBtn }) => {


    const { Qr, contactDetails,
        divorceDetails,
        educationQualification
        , extraInformation,
        familyDetail,
        kundliDetails,
        mamaDetails,
        marriedStatus,
        matrimonyCode,

        occupationDetails,
        othersDetails,
        personalInformation,
        photos,
        samajReferenceDetails

    } = bioData;

    const { personalPhoto1, personalPhoto2, profilePhoto } = photos
    const photo = [profilePhoto, personalPhoto1, personalPhoto2,]

    const { bloodGroup, currentAddress, currentCity, currentCountry, currentMaritalStatus, currentState, gender,
        heightFeet, heightInch
        ,
        gotra,
        samaj,
        name,
        surname,
        weightInKG,
        skinColor,

    } = personalInformation || ""

    const { birthDateTime, birthPlace, mangalik, rashiByDateOfBirth
        , rashi } = kundliDetails || ""

    const { mamaGotra,
        mamaMobileNo,
        mamaName,
        mamaOccupation,
        mamaPhoto } = mamaDetails || ""

    const {
        mobileNo,
        whatsappNo,
        email,
        emailId,
        facebookId,
        instagramId,
        linkedInId
    } = contactDetails || ""

    const {
        fatherName,
        motherName
    } = familyDetail || ""

    const { occupation,
        averageSalaryPerMonth,
        companyName,
        designation,
        occupationCity,
        occupationCountry,
        occupationState

    } = occupationDetails || ""
    const { achievementsAwards, expectationFromRelation, hobbies } = othersDetails || ""
    return (
        <>
            <div className=' flex w-full justify-center items-center flex-col ' style={{ backgroundImage: firstPage }}>
                <div className=' relative' >
                    <img className=' w-[200px] h-[200px] rounded-md animate-spin-slow '
                        src={photoFrem} loading='lazy' />
                    <h1 className=' w-full text-center text-2xl my-2 font-bold text-white'>{name}</h1>
                    <img className=' absolute top-10 left-10 w-[120px] h-[120px] rounded-full'
                        src={`${Domain + photos?.profilePhoto}`}
                        loading='lazy' />
                </div>
                <img className=' w-[120px] h-[120px] rounded-md' src={`${Domain + Qr}`} />

            </div>
            {/* presnal information */}
            <div className='bg-white rounded-b-3xl rounded-t-xl border-2 border-yellow-600 mt-3'>
                {/* <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Personal Information'} /> */}
                <div className=' w-full text-center rounded-t-xl  bg-yellow-500 col- text-black font-bold text-2xl'>व्यक्तिगत जानकारी</div>

                <div className='  grid grid-cols-1     shadow-2xl  p-2 md:grid-cols-2 text-[14px]      md:gap-y-4 md:gap-x-10'>

                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Membership Code / मेंबरशिप कोड:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{matrimonyCode}</p>
                    </div>
                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Name / नाम:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{name}</p>
                    </div>
                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Surname / उपनाम:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{surname}</p>
                    </div>
                    {mobileNo && (
                        <>
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Mobile no / मोबाइल नंबर:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{mobileNo}</p>
                            </div>
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Whatsapp No / व्हाट्सएप नंबर:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{whatsappNo}</p>
                            </div>
                        </>
                    )}
                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Father’s Name / पिता का नाम:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{fatherName}</p>
                    </div>
                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Mother’s Name / माताजी का नाम:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{motherName}</p>
                    </div>
                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Samaj / समाज :</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{samaj}</p>
                    </div>
                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Gotra / गोत्र:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{gotra}</p>
                    </div>
                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Birth Date / जन्म तिथि:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{new Date(birthDateTime).toDateString()}</p>
                    </div>

                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Gender:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{gender}</p>
                    </div>
                    {/* <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Blood Group / ब्लड ग्रुप:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{bloodGroup}</p>
                    </div> */}

                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>height / ऊंचाई:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{`${heightFeet}'${heightInch} Feet`}</p>
                    </div>

                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>weight / वज़न:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{weightInKG}KG</p>
                    </div>

                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>skin Color / त्वचा का रंग:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{skinColor}</p>
                    </div>

                    {/* <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>Handicap / विकलांग:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{handicapped ? handicapped : "NO"}</p>
                    </div> */}
                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>current Marital Status / वर्तमान वैवाहिक स्थिति:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{currentMaritalStatus}</p>
                    </div>

                    {currentMaritalStatus === 'Devorcee' &&

                        divorceDetails?.map((child, index) => {
                            return <>
                                <div className=''>
                                    <p className='text-start text-[15px] font-extrabold text-black  '>child {index + 1}:</p>
                                    <p className='font-semibold text-[12px] text-blue-600'>{child?.Children}</p>
                                </div>
                                <div className=''>
                                    <p className='text-start text-[15px] font-extrabold text-black  '> Age {index + 1}:</p>
                                    <p className='font-semibold text-[12px] text-blue-600'>{child?.ChildrenAge}</p>
                                </div>
                            </>
                        })

                    }

                    {/* divorceDetails */}
                </div>

            </div>

            <div className='bg-white rounded-b-3xl rounded-t-xl border-2 border-yellow-600 mt-3'>
                {/* <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Personal Information'} /> */}
                <div className=' w-full text-center rounded-t-xl  bg-yellow-500  text-black font-bold text-2xl capitalize'>कुंडली विवरण</div>
                <div className='  grid grid-cols-1     shadow-2xl  p-2 md:grid-cols-2 text-[14px]      md:gap-y-4 md:gap-x-10'>

                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize '>birth Place / जन्म स्थान:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{birthPlace}</p>
                    </div>
                    <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>mangalik / मांगलिक:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{mangalik}</p>
                    </div>
                    {/* <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize'>rashi By birth / राशि जन्म से:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{rashi}</p>
                    </div> */}

                    {/* <div className=''>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>rashi By Date Of Birth / जन्म तिथि के अनुसार राशि:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{rashiByDateOfBirth}</p>
                    </div> */}





                </div>

            </div>

            {/* mama */}

            <div className='bg-white rounded-b-3xl rounded-t-xl border-2 border-yellow-600 mt-3'>
                <div className=' w-full text-center rounded-t-xl  bg-yellow-500 col- text-black font-bold text-2xl capitalize'> मामा का विवरण</div>

                {/* <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Address'} /> */}
                <div className='  grid grid-cols-1    rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                    {/* <div className=' '>
                    <p className='text-start text-[15px] font-extrabold text-black  ' >Permanent Address / स्थाई पता: </p>
                    <p className='font-semibold text-[12px] text-blue-600'>{permanentAddress}</p>
                </div> */}

                    <div className='   '>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>mama Name / मामा का नाम: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{mamaName}</p>
                    </div>
                    <div className='  '>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>mama Gotra / मामा का गोत्र:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{mamaGotra}</p>


                    </div>

                    {/* <div className='   '>
                        <div className=''>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>mama Mobile No / मामा का मोबाइल नंबर: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{mamaMobileNo}</p>
                        </div>
                    </div>
                    <div className='   '>
                        <div className=''>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>mama Occupation / मामा का व्यवसाय: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{mamaOccupation}</p>


                        </div>
                    </div> */}

                </div>
            </div>


            <div className='bg-white rounded-b-3xl rounded-t-xl border-2 border-yellow-600 mt-3'>
                <div className=' w-full text-center rounded-t-xl  bg-yellow-500 col- text-black font-bold text-2xl capitalize'>सम्पर्क करने का विवरण</div>

                {/* <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Address'} /> */}
                <div className='  grid grid-cols-1    rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                    {/* <div className=' '>
                    <p className='text-start text-[15px] font-extrabold text-black  ' >Permanent Address / स्थाई पता: </p>
                    <p className='font-semibold text-[12px] text-blue-600'>{permanentAddress}</p>
                </div> */}

                    <div className='   '>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>Mobile No / मोबाइल नंबर: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{mobileNo}</p>
                    </div>




                    <div className='  '>
                        <p className='text-start text-[15px] font-extrabold text-black   capitalize'>whatsapp No / व्हाट्सएप नंबर:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{whatsappNo}</p>
                    </div>

                    <div className='   '>
                        <div className=''>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>email Id / ईमेल आईडी : </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{emailId}</p>
                        </div>
                    </div>
                    {/* <div className='   '>
                        <div className=''>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>facebook Id / फेसबुक आईडी: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{facebookId}</p>
                        </div>
                    </div> */}

                    {/* <div className='   '>
                        <div className=''>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>instagram Id / इंस्टाग्राम आईडी: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{instagramId}</p>
                        </div>
                    </div> */}

                    {/* <div className='   '>
                        <div className=''>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>linkedIn Id / लिंक्डइन आईडी: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{linkedInId}</p>
                        </div>
                    </div> */}

                </div>
            </div>

            {/* address */}
            <div className='bg-white rounded-b-3xl rounded-t-xl border-2 border-yellow-600 mt-3'>
                <div className=' w-full text-center rounded-t-xl  bg-yellow-500 col- text-black font-bold text-2xl capitalize'>पता</div>

                {/* <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Address'} /> */}
                <div className='  grid grid-cols-1    rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                    {/* <div className=' '>
                    <p className='text-start text-[15px] font-extrabold text-black  ' >Permanent Address / स्थाई पता: </p>
                    <p className='font-semibold text-[12px] text-blue-600'>{permanentAddress}</p>
                </div> */}

                    <div className='   '>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Current Address / वर्तमान पता: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{currentAddress}</p>
                    </div>
                    <div className='  '>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Current Address Area / पता एरिया:</p>
                        <p className='font-semibold text-[12px] text-blue-600'>{currentCity?.cityName}</p>


                    </div>

                    <div className='   '>
                        <div className=''>  <p className='text-start text-[15px] font-extrabold text-black  '>City name / शहर का नाम: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{currentCity?.cityName}</p>
                        </div>
                    </div>
                    <div className='   '>
                        <div className=''>  <p className='text-start text-[15px] font-extrabold text-black  '>State name / स्टेट का नाम: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{currentState
                                ?.stateName}</p>
                        </div>
                    </div>
                    <div className='   '>
                        <div className=''>  <p className='text-start text-[15px] font-extrabold text-black  '>Country name / देश का नाम: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{currentCountry?.countryName}</p>

                        </div>
                    </div>
                    {/* <div className=' '>  <p className='text-start text-[15px] font-extrabold text-black  '>Pincode / पिनकोड: </p>
                    <p className='font-semibold text-[12px] text-blue-600'>{popupData.pincode}</p>
                </div> */}
                </div>
            </div>
            {/* Occupation */}
            <div className='bg-white rounded-b-3xl rounded-t-xl border-2 border-yellow-600 mt-3'>
                <div className=' w-full text-center rounded-t-xl  bg-yellow-500 col- text-black font-bold text-2xl capitalize'>व्यवसाय और शिक्षा</div>
                {/* <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Occupation & Education'} /> */}
                <div className='  grid grid-cols-1 rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                    <div className=' '>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Education Qualification / शैक्षणिक योग्यता: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{educationQualification}</p>
                    </div>
                    <div className='  '>  <p className='text-start text-[15px] font-extrabold text-black  '>Occupation / व्यवसाय: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{occupation}</p>
                    </div>
                </div>
                <div className='  grid grid-cols-1 rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                    <div className=' '>
                        <p className='text-start text-[15px] font-extrabold text-black  '>Education Qualification / शैक्षणिक योग्यता: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{educationQualification}</p>
                    </div>
                    <div className='  '>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>average Salary PerMonth / प्रति माह औसत वेतन: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{averageSalaryPerMonth}</p>
                    </div>

                    <div className='  '>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>company Name / कंपनी का नाम: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{companyName}</p>
                    </div>
                    <div className='  '>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>designation / पद: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{designation}</p>
                    </div>
                    <div className='  '>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>occupation City / व्यवसाय शहर: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{occupationCity?.cityName}</p>
                    </div>
                    {/* <div className='  '>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>occupation State / व्यवसाय राज्य: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{occupationState?.stateName}</p>
                    </div>
                    <div className='  '>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '> occupation Country / व्यवसाय देश: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{occupationCountry?.countryName}</p>
                    </div> */}

                    <div className='  '>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '> education Qualification / शैक्षणिक योग्यता: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{educationQualification}</p>
                    </div>

                </div>
            </div>
            {/* <div className='bg-white rounded-b-3xl rounded-t-xl border-2 border-yellow-600 mt-3'>
                <div className=' w-full text-center rounded-t-xl  bg-yellow-500 col- text-black font-bold text-2xl capitalize'>अन्य विवरण</div>
                <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Occupation & Education'} />
                <div className='  grid grid-cols-1 rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                    <div className=' '>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>achievements Awards / उपलब्धियां पुरस्कार: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{achievementsAwards}</p>
                    </div>
                    <div className='  '>  <p className='text-start text-[15px] font-extrabold text-black capitalize  '>expectation From Relation / रिश्ते से उम्मीद: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{expectationFromRelation}</p>
                    </div>
                </div>
                <div className='  grid grid-cols-1 rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                    <div className=' '>
                        <p className='text-start text-[15px] font-extrabold text-black capitalize  '>hobbies / रुचियाँ: </p>
                        <p className='font-semibold text-[12px] text-blue-600'>{hobbies}</p>
                    </div>

                </div>
            </div> */}

            <div className=' w-full flex flex-wrap gap-4 mt-4 justify-center items-center'>
                {photo?.map((img, index) => (
                    <>
                        {img && <ImagePopup key={index} src={Domain + img} alt="Sample Image" />}
                    </>

                ))
                }
            </div>



        </>
    )

}


const ImagePopup = ({ src, alt }) => {
    const [isZoomed, setIsZoomed] = useState(false);

    const handleZoomChange = (e) => {
        e.stopPropagation()
        setIsZoomed(prev => !prev);
    };




    return (
        <div onClick={handleZoomChange} className={`${isZoomed ? ' h-screen fixed top-0 left-0 z-[9999999] bg-white  flex justify-center items-center' : " shadow-all-sides p-2 rounded-md"}`}
        >
            <img
                src={src}
                alt={alt}
                className={`m-auto trans  ${isZoomed ? 'transition-all duration-300 ease-in-out w-screen h-[100%] object-contain ' : 'w-52 h-52 '}`}
                loading='lazy'
            />

        </div>
    );
};








