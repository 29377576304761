import React from 'react'
import rulesPage1 from "../../../assets/landingpage/rules_page-1.jpg"

export default function Rules() {
    return (
        <div className=' m-0 w-full bg-red-900'>
            <img className=' w-full' src={rulesPage1} />
        </div>
    )
}
