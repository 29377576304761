import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { FormHeading } from '../../Forms';
import { ScanMatrimonyByQr, ScanMatrimonyShareableQr } from '../../service/Services';
import LoadingModal from '../../Forms/LoadingModel';
import { Domain } from '../../ApiConfig';
import photoFrem from "../../assets/matrimonyThims/photofframe.png"

export default function MatrimonyShearQrView() {
    const { token } = useParams();
    const [scanedProfile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchScannedMember = async () => {
            try {
                setLoading(true);
                const scannedMember = await ScanMatrimonyShareableQr({ token });
                if (scannedMember?.success) {
                    setProfile(scannedMember.data);

                } else {
                    setError('Failed to fetch profile data.');
                }
            } catch (error) {
                console.error('Error fetching scanned member:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchScannedMember();
    }, [token]);

    if (loading) {
        return <LoadingModal show={loading} text="Scanning..." />;
    }

    if (error) {
        return <div className="text-red-500 text-center mt-4">{error}</div>;
    }

    if (!scanedProfile) {
        return null; // Or you could return a message indicating no profile found
    }

    const {
        Qr, contactDetails = {}, divorceDetails, educationQualification, extraInformation, familyDetail = {}, kundliDetails = {}, mamaDetails, marriedStatus, matrimonyCode, occupationDetails = {}, othersDetails, personalInformation = {}, photos = {}, samajReferenceDetails
    } = scanedProfile;

    const { bloodGroup, currentAddress, currentCity, currentCountry, currentMaritalStatus, currentState, gender, heightFeet, heightInch, permanentCity, gotra, name, surname, permanentState, permanentCountry } = personalInformation;

    const { birthDateTime } = kundliDetails;

    const { mobileNo, whatsappNo, email } = contactDetails;

    const { fatherName, motherName } = familyDetail;

    const { occupation } = occupationDetails;

    return (
        <>
            <div className=' bg-pink-900 w-full flex flex-col items-center'>
                <div className=' relative' >
                    <img className=' w-[200px] h-[200px] rounded-md animate-spin-slow ' src={photoFrem} />
                    <img className=' absolute top-10 left-10 w-[120px] h-[120px] rounded-full' src={`${Domain + photos?.profilePhoto}`} />
                </div>

                <FormHeading style="bg-yellow-500 text-center rounded-md" heading="Personal Information" />

                <div className="grid w-full grid-cols-1 rounded-md shadow-2xl p-2 md:grid-cols-2 text-[14px] bg-white md:gap-y-4 md:gap-x-10">
                    {[
                        { label: 'Membership Code / मेंबरशिप कोड:', value: matrimonyCode },
                        { label: 'Name / नाम:', value: name },
                        { label: 'Surname / उपनाम:', value: surname },
                        { label: 'Mobile no / मोबाइल नंबर:', value: mobileNo },
                        { label: 'Whatsapp No / व्हाट्सएप नंबर:', value: whatsappNo },
                        { label: 'Father’s Name / पिता का नाम:', value: fatherName },
                        { label: 'Mother’s Name / माताजी का नाम:', value: motherName },
                        { label: 'Gotra / गोत्र:', value: gotra },
                        { label: 'Birth Date / जन्म तिथि:', value: birthDateTime },
                        { label: 'Email id / ईमेल आईडी:', value: email },
                        { label: 'Gender:', value: gender },
                        { label: 'Blood Group / ब्लड ग्रुप:', value: bloodGroup },
                    ].map(({ label, value }, idx) => (
                        value && (
                            <div key={idx}>
                                <p className="text-start text-[15px] font-extrabold text-black">{label}</p>
                                <p className="font-semibold text-[12px] text-blue-600">{value}</p>
                            </div>
                        )
                    ))}
                </div>

                <FormHeading style="bg-yellow-500 text-center rounded-md" heading="Address" />
                <div className="grid w-full grid-cols-1 rounded-md shadow-2xl p-2 md:grid-cols-2 text-[14px] bg-white md:gap-y-4 md:gap-x-10">
                    {[
                        { label: 'Current Address / वर्तमान पता:', value: currentAddress },
                        { label: 'Current Address Area / पता एरिया:', value: currentCity?.cityName },
                        { label: 'City name / शहर का नाम:', value: permanentCity?.cityName },
                        { label: 'State name / स्टेट का नाम:', value: permanentState?.stateName },
                        { label: 'Country name / देश का नाम:', value: permanentCountry?.countryName },
                    ].map(({ label, value }, idx) => (
                        value && (
                            <div key={idx}>
                                <p className="text-start text-[15px] font-extrabold text-black">{label}</p>
                                <p className="font-semibold text-[12px] text-blue-600">{value}</p>
                            </div>
                        )
                    ))}
                </div>

                <FormHeading style="bg-yellow-500 text-center rounded-md" heading="Occupation & Education" />
                <div className="grid w-full grid-cols-1 rounded-md shadow-2xl p-2 md:grid-cols-2 text-[14px] bg-white md:gap-y-4 md:gap-x-10">
                    {[
                        { label: 'Education Qualification / शैक्षणिक योग्यता:', value: educationQualification },
                        { label: 'Occupation / व्यवसाय:', value: occupation },
                    ].map(({ label, value }, idx) => (
                        value && (
                            <div key={idx}>
                                <p className="text-start text-[15px] font-extrabold text-black">{label}</p>
                                <p className="font-semibold text-[12px] text-blue-600">{value}</p>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </>
    );
}
