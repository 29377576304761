import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import jsQR from 'jsqr';
import logo from '../assets/login card/logo.png';
import { Input } from '../FormsInputes.js';
import { useForm } from 'react-hook-form';
import { samajEntry } from '../service/Services.js';
import Swal from 'sweetalert2';
import LoadingModal from '../Forms/LoadingModel.js';
import { showSuccessAlert } from './ShowAlertCustome.js';

const QrScanner = () => {
    const [scanResult, setScanResult] = useState(null); // Use `null` instead of empty string for better checks
    const [error, setError] = useState('');
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const contextRef = useRef(null);
    const scanningRef = useRef(false);

    // Function to initialize the scanner
    const initializeScanner = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
            const video = videoRef.current;
            if (video) {
                video.srcObject = stream;
                video.setAttribute('playsinline', true);

                video.addEventListener('loadedmetadata', () => {
                    video.play();
                    const canvas = canvasRef.current;
                    if (canvas) {
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;
                        contextRef.current = canvas.getContext('2d');
                    }
                });

                video.addEventListener('playing', () => {
                    const scan = () => {
                        const videoElement = videoRef.current;
                        const canvasElement = canvasRef.current;
                        const context = contextRef.current;

                        if (videoElement && canvasElement && context && videoElement.readyState === videoElement.HAVE_ENOUGH_DATA) {
                            context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
                            const imageData = context.getImageData(0, 0, canvasElement.width, canvasElement.height);
                            const code = jsQR(imageData.data, canvasElement.width, canvasElement.height);

                            if (code) {
                                if (!scanningRef.current) {
                                    scanningRef.current = true;
                                    console.log('code.data', code.data)
                                    setScanResult(code.data);
                                    // Stop the video stream
                                    if (videoElement && videoElement.srcObject) {
                                        const stream = videoElement.srcObject;
                                        const tracks = stream.getTracks();
                                        tracks.forEach(track => track.stop());
                                    }
                                }
                            } else {
                                scanningRef.current = false;
                            }
                        }

                        requestAnimationFrame(scan);
                    };

                    scan();
                });
            } else {
                throw new Error('Video element is not available.');
            }
        } catch (err) {
            setError('Failed to start QR scanner. Make sure your camera is accessible.');
            console.error(err);
        }
    };

    // Start or reset the scanner when needed
    const startScanner = () => {
        setScanResult(null);
        setError('');
        initializeScanner();
    };

    // Clean up the scanner when the component unmounts or when scanResult changes
    useEffect(() => {
        startScanner();
        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                const stream = videoRef.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }
        };
    }, []);

    return (
        <div>
            <div className=' w-full flex justify-center my-3'>
                <button className='  text-white font-bold  btn bg-orange-600' onClick={startScanner}>Scan Agin</button>
            </div>
            {scanResult ? (
                <div>
                    <Card qrdata={scanResult} />

                </div>
            ) : (
                <>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <video ref={videoRef} style={{ width: '100%' }}></video>
                    <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                    <p>Scan Result: {scanResult}</p>
                </>
            )}
        </div>
    );
};

export default QrScanner;

// Card component to display QR code data
const Card = ({ qrdata }) => {
    // Initialize data state
    const [data, setData] = useState({});
    const { register, handleSubmit, setError, watch, formState: { errors }, setValue, getValues, reset } = useForm();
    const [isLoading, setLoading] = useState(false)



    useEffect(() => {
        try {
            // Parse the JSON string into an object
            const parsedData = JSON.parse(qrdata);
            setData(parsedData);
        } catch (e) {
            console.error('Error parsing QR data:', e);
            setData({}); // Set data to an empty object on parse error
        }
    }, [qrdata]);

    // Ensure data has the expected properties
    const { matrimonyCode, name, profilePhoto, city, gotra } = data;

    const handleChange = (fieldName, e) => {
        let value = e.target.value

        setValue(fieldName, value, { shouldValidate: true });

    };

    const handleFromSubmit = async (data) => {

        try {
            setLoading(true)
            const { noOfPerson } = data

            console.log("noOfPerson", noOfPerson, matrimonyCode)

            const response = await samajEntry({ noOfPerson, matrimonyCode })
            if (response?.success) {
                // navigate('/')
                reset();
                showSuccessAlert(response.message, logo)
            } else
                if (response.error) {
                    Swal.fire({
                        title: response.error,
                        text: response.error,
                        icon: 'error',
                    });
                }

        } catch (error) {

            Swal.fire({
                title: error,
                text: error,
                icon: 'error',
            });
            // setIsApiCall(false)
        } finally {

            setLoading(false)
        }
    };



    return (
        <div className='w-100 flex justify-center items-center flex-col'>
            <LoadingModal show={isLoading} text={'Entering...'} />
            <div className='w-100 flex justify-center'>
                <div className='card p-4 max-w-[380px]'>
                    <div className='flex justify-between items-center'>
                        <img className='w-12 h-12' src={logo} alt='Logo' loading='lazy' />
                        <h1 className='text-2xl text-pink-900 font-bold'>अ.भा.वैश्य परिचय सम्मेलन</h1>
                    </div>
                    <div className='flex my-2 items-center'>

                        <>
                            <img
                                className="w-20 h-20 me-2"
                                src={profilePhoto || 'fallback-image-url'}
                                alt="Profile"
                                loading="lazy"
                            />
                            <div>
                                <p className='text-[1rem] font-bold text-gray-600'>ID No.- {matrimonyCode}</p>
                                <p className='text-[1.2rem] font-bold text-black'>{name}</p>

                                <p className='text-[1.2rem] font-bold text-orange-600'>{city}</p>
                                <p className='text-[1.2rem] font-bold text-orange-600'>{gotra}</p>
                            </div>
                        </>

                    </div>
                </div>
            </div>

            <form onSubmit={handleSubmit(handleFromSubmit)} className=' w-full flex  items-center flex-col'>


                <div className="sm:col-span-3">
                    <Input
                        label="Number of People"
                        placeholder="Number of People"
                        type="number"
                        required="required"
                        {...register("noOfPerson", {
                            value: 1,
                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                            pattern: {
                                value: /^[0-9]{1,2}$/, // Matches 1 or 2 digits
                                message: "कृपया मान्य 1 या 2 अंकों का नंबर दर्ज करें"
                            }
                        })}
                        onChange={(e) => handleChange("noOfPerson", e)}
                    />
                    {errors.noOfPerson && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.noOfPerson.message}</p>}
                </div>




                {!isLoading ? <button type="submit" class="text-white my-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">

                    Add...
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </button> :
                    <button disabled type="button" class="text-white my-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                        <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                        </svg>
                        Loading...
                    </button>}

            </form>

        </div>
    );
};
