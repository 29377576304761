import React, { forwardRef, useId } from 'react'

function SelectInput({
    options,
    label,
    className = "",
    required,
    alreadySelect,
    ...props }, ref) {
    const id = useId()

    return (
        <div className=' w-full' >
            {label && <label htmlFor={id} className={` ${required ? 'required' : ""} block capitalize font-extrabold text-sm  leading-6 text-gray-900`}>
                {label}
            </label>}
            <div className="mt-1">
                <select
                    id={id}
                    ref={ref}
                    className={`block ${className}  px-2 w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    {...props}
                >

                    {alreadySelect && <option value={alreadySelect} >{alreadySelect}</option>}
                    {options.map((option, index) => (
                        <option value={option} key={index}>{option}</option>
                    ))}
                </select>

            </div>
        </div>
    )
}
export default forwardRef(SelectInput)
