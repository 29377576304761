import { useEffect, useState } from "react";
import { searchAMtrimonyForAddresPage } from "../../service/Services";
import LoadingModal from "../../Forms/LoadingModel";
import { Pagination } from "./userManagement";
import { Domain } from "../../ApiConfig";

export function AdreesPage() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [matrimonyCode, setMatrimonyCode] = useState("");

    const limit = 10; // Number of entries per page

    useEffect(() => {
        // Fetch the data when the component mounts or when the page/matrimonyCode changes
        fetchData(currentPage, limit, matrimonyCode);
    }, [currentPage, matrimonyCode]);

    const fetchData = async (page, limit, matrimonyCode) => {
        setLoading(true);
        try {
            // Make the API call with current page, limit, and matrimonyCode
            const response = await searchAMtrimonyForAddresPage(page, limit, matrimonyCode);

            // Ensure data and pagination exist in the response
            if (response.data) {
                setData(response.data); // assuming response.data contains the fetched data
                setTotalPages(response.pagination.totalPages); // assuming response.pagination has totalPages
            } else {
                setData([]);
                setTotalPages(1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            {/* Input for Matrimony Code */}
            <div className=" w-full flex justify-center">
                <div className="max-w-[350px] ">
                    <label htmlFor="MatrimonyAdress" className="capitalize block ${required} text-sm font-extrabold  leading-6 text-gray-900" >Matrimony Code</label>
                    <input
                        type="text"
                        id="MatrimonyAdress"
                        value={matrimonyCode}
                        onChange={(e) => setMatrimonyCode(() => e.target.value)}
                        placeholder="Enter Matrimony Code"
                        className="block  px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>
            <div className="container mx-auto my-5">


                {/* <h1 className="text-2xl font-bold mb-4">Samelan Entry Report</h1> */}

                {/* Loading modal */}
                <LoadingModal show={loading} text={'Loading...'} />

                {/* Table for displaying data */}
                <div className="overflow-x-auto">
                    <table className="min-w-full border-collapse">
                        <thead>
                            <tr className="w-full text-sm bg-blue-950 text-white text-left">
                                <th className="py-1 px-2 text-left border-2">क्रमांक</th>
                                <th className="py-1 px-2 text-left border-2">नाम</th>
                                <th className="py-1 px-2 text-left border-2">पता</th>

                                <th className="py-1 px-2 text-left border-2">समाज</th>
                                <th className="py-1 px-2 text-left border-2">विवाह कोड</th>
                                <th className="py-1 px-2 text-left border-2">मोबाइल नंबर</th>

                            </tr>
                        </thead>
                        <tbody>
                            {data.length === 0 ? (
                                <tr>
                                    <td colSpan="9" className="text-center py-4">
                                        No entries found.
                                    </td>
                                </tr>
                            ) : (
                                data.map((entry, index) => {
                                    const { personalInformation: { name, samaj, gotra, currentAddress }, contactDetails: { mobileNo }, photos: { profilePhoto }, matrimonyCode, } = entry;



                                    {/* "_id": "66db4216117306b6d90ab83a",
            "matrimonyCode": "MAT06039",
            "personalInformation": {
                "name": "Priya Porwal (Dolly Porwal)",
                "samaj": "पोरवाल पुरवार",
                "gotra": "अन्य गोत्र",
                "currentAddress": "6/174 Chauraha khadi bhandar tajganj"
            },
            "contactDetails": {
                "mobileNo": "9027862316"
            },
            "photos": {
                "profilePhoto": "public/matrimonyProfile/1725645334226-199976644profilePhoto.jpg"
            } */}
                                    return (
                                        <tr key={index} className="odd:bg-white text-sm even:bg-gray-50">
                                            <td className="py-1 px-2 border-2">{index + 1}</td>
                                            <td className="py-1 px-2 border-2 flex min-w-56">
                                                <img className="w-10 h-10 rounded-full me-8" src={Domain + profilePhoto} alt="Profile" />
                                                <div className="mt-auto">
                                                    <h2 className="text-black font-bold">{name}</h2>
                                                    <small>{gotra}</small>
                                                </div>
                                            </td>
                                            <td className="py-1 px-2 border-2">{currentAddress}</td>
                                            <td className="py-1 px-2 border-2">{samaj}</td>
                                            <td className="py-1 px-2 border-2">{matrimonyCode}</td>
                                            <td className="py-1 px-2 border-2">{mobileNo}</td>


                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
        </>
    );
}
