import { useCallback, useRef } from 'react';

const useIntersectionObserver = ({ currentPage, totalPages, paginationData, setCurrentPage }) => {
    const observer = useRef(null);
    const lastItemRef = useCallback(node => {

        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && currentPage < paginationData?.totalPages && paginationData?.hasNextPage
            ) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [paginationData]);

    return lastItemRef;
};

export default useIntersectionObserver;
