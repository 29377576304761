import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';

const MyDropzone = ({ images = [], setImages }) => {


    const onDrop = useCallback((acceptedFiles) => {
        const newImages = acceptedFiles.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        );

        setImages(prevImages => [...prevImages, ...newImages]);

        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result;

            };
            reader.readAsArrayBuffer(file);
        });
    }, []);


    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const fileRemoveHandler = (e, index) => {
        e.preventDefault()

        const newImages = images.filter((_, i) => i !== index);
        setImages(newImages);
    };

    return (
        <div className="container col-span-12 mx-auto p-4 ">
            <div {...getRootProps({ className: 'border-dashed w-full  border-2 border-gray-400 h-40 flex justify-center items-center' })}>
                <input className='h-40 w-full' {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            {images.length > 0 && (
                <div className="mt-4">
                    <h2 className="text-xl font-bold mb-2">Uploaded Images</h2>
                    <div className=" ">
                        {images.map((file, index) => {
                            console.log('file', file)
                            return <div key={index} className=' w-full  my-4 flex items-center justify-between px-5  bg-green-300'>
                                <div className=' flex items-center gap-x-3'>
                                    <img

                                        src={file.preview}
                                        alt={`uploaded ${index}`}
                                        className="w-10 h-10"
                                    />
                                    <small>{file.name}</small>
                                </div>
                                <button onClick={(e) => fileRemoveHandler(e, index)} className=' text-red-600 font-bold cursor-pointer'>X</button>

                            </div>
                        }
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyDropzone;
