// ImageCarousel.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Ads1 from "../assets/ads1.png"
import Ads2 from "../assets/ads2.png"
import vaishyaslider1 from "../assets/landingpage/vaishyaimage11witimage.png"
import slider2 from "../assets/landingpage/slider2.png"
import slider3 from "../assets/landingpage/slider3.png"
// import adhyksh from "../assets/CommitteeMember/adhaksh.jpeg"
import image from "../assets/landingpage/image.png"
import leftShenahi from "../assets/landingpage/leftShenahi.png"
import rigthShenahi from "../assets/landingpage/rigthShenahi.png"
// import './ImageCarousel.css';  // Custom styles for the carousel
import photoFrem from '../assets/matrimonyThims/photofframe.png'


const ImageCarousel = () => {

    const images = [
        { src: <FirstSlider />, alt: 'vaishyaslider1', caption: 'Order Now' },
        { src: <SecondSlider />, alt: 'vaishyaslider2', caption: 'Book Now' },
        { src: <TherdSlider />, alt: 'vaishyaslider3', caption: 'Book Now' },
        // { src: 'https://my-online-shop.netlify.app/static/media/slider1.c8bfec124194f77c135c.jpg', alt: 'Slide 3', caption: 'Slide 3 Caption' },
    ];

    const customPrevArrow = (onClickHandler, hasPrev, label) =>
        hasPrev && (
            <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className=" absolute left-5 top-1/2 transform -translate-y-1/2 bg-transparent text-white p-2 rounded-full shadow-lg focus:outline-none z-10"
            >
                &larr;
            </button>
        );

    const customNextArrow = (onClickHandler, hasNext, label) =>
        hasNext && (
            <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className=" absolute right-5 top-1/2 transform -translate-y-1/2 bg-transparent text-white p-2 rounded-full shadow-lg focus:outline-none z-10"
            >
                &rarr;
            </button>
        );
    return (
        <div className="carousel-container h-[90%]  ">
            <Carousel
                showArrows={true}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                className="carousel"
                renderArrowPrev={customPrevArrow}
                renderArrowNext={customNextArrow}
            >
                {images.map((image, index) => (
                    <div className=' w-full  sm:h-[80vh] md:h-[90vh]     flex justify-center ' key={index}>
                        {image.src}
                        {/* <img className=' lg:h-[700px] object-contain ' src={image.src} alt={image.alt} /> */}
                        {/* <div className="lg bottom-5 w-full flex justify-center">
                            <p className=" cursor-pointer font-bold w-30 bg-blue-600 text-white py-1 px-4 rounded-lg shadow-lg">{image.caption}</p>
                        </div> */}
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default ImageCarousel;

const FirstSlider = () => {


    return <>
        <div className=' w-full   h-[66vh]  sm:h-full       bg-[#6B0328] ' >
            {/* <img className='' src={vaishyaslider1} /> */}
            <h1 className=' text-yellow-400  font-bold text-2xl mt-2'>अ.भा.वैश्य परिचय सम्मेलन</h1>
            <div className=' relative'>
                <img className=' absolute lg top-[0.3rem]  left-2  w-[100px]    h-[180px] md:w-[220px]  md:h-[220px]   object-contain rounded-lg animate-spin-slow ' src={photoFrem} loading='lazy' />
                <img className=' absolute lg top-[3rem] md:top-[3.5rem] left-[0.6rem]  h-[95px] w-[55px] md:w-[120px]  md:h-[120px]  object-contain  rounded-full' src={image} loading='lazy' />
            </div>
            <div className=' text-bold ms-2 mt-[11rem] md:mt-[14rem] text-white  sm:top-[12.9rem]  sm:text-[2rem]    text-center w-full'>
                <p className='text-2xl sm:text-[3rem] md:text-[2rem]     sm:my-8 md:my-3 font-bold' >प्रेरणा स्त्रोत: </p>
                <p className='text-2xl  sm:text-[3rem] md:text-[2rem]   sm:my-8 md:my-3 font-bold'>स्वर्गीय श्री रमेश जी अग्रवाल</p>
                <p className=' font-bold text-yellow-400 text-[15px] '>अखिल भारतीय वैश्य युवक-युवती परिचय सम्मलेन</p>
                <p className=' font-bold text-yellow-400 text-[16px] '>शनिवार - रविवार 21, 22 सितंबर 2024</p>
                <p className=' font-bold text-yellow-400 text-[16px]'>स्थान - दस्तूर गार्डन, स्कीम नं. 71</p>
                <p className=' font-bold text-yellow-400 text-[16px]'>रिंग रोड, इंदौर</p>
                <p className=' font-bold text-yellow-400 text-[16px]'>आयोजक - वैश्य महासम्मेलन मध्यप्रदेश</p>
                <p className=' font-bold text-yellow-400 text-[16px] '>बागड़ी सदन, 30, श्रद्धानन्द मार्ग, </p>
                <p className=' font-bold text-yellow-400 text-[16px] '>छावनी इंदौर</p>

            </div>
            <div className=' w-full  mt-[-5rem] sm:mt-[-10rem] md:mt-[-14rem]   flex justify-between'>
                <div className='w-[60px] h-auto sm:w-[120px]  '>
                    <img className='   ' src={leftShenahi} />
                </div>
                <div className='w-[50px] h-auto sm:w-[110px]  '>
                    <img className='  ' src={rigthShenahi} />
                </div>
            </div>
        </div>

    </>

}

const SecondSlider = () => {
    return <>
        <div className='  '>
            <img className='w-[100%] h-[100%]' src={slider2} />
        </div>
    </>
}

const TherdSlider = () => {
    return <>
        <div className='  '>
            <img className='w-[100%] h-[100%] ' src={slider3} />
        </div>

    </>

}
