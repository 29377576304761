import React from 'react'
import QrScanner from '../../Components/QrReader'

export default function QrReader() {
    return (
        <div>
            <QrScanner />
        </div>
    )
}
