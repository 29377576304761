import React, { useRef, useState } from 'react';
import { FcAddImage } from 'react-icons/fc';
import AvatarEditor from 'react-avatar-editor';
import { Domain } from '../ApiConfig';

const Cropper = ({ setEditedAvatar, alreadySetAvatar }) => {
    const [src, setSrc] = useState(null);
    const [preview, setPreview] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const inputRef = useRef(null);

    const handleInputClick = (e) => {
        e.preventDefault();
        inputRef.current.click();
    };

    const handleImgChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSrc(URL.createObjectURL(file));
            setModalOpen(true);
        } else {
            setSrc(null);
            setModalOpen(false);
        }
        e.target.value = ''; // Reset the input value to allow re-selection of the same file
    };

    return (
        <>
            <header>
                <hr />
            </header>
            <main className="flex flex-col justify-center items-center">
                <CropperModal
                    modalOpen={modalOpen}
                    src={src}
                    setPreview={setPreview}
                    setModalOpen={setModalOpen}
                    setEditedAvatar={setEditedAvatar}
                />
                <a href="/" onClick={handleInputClick}>
                    <FcAddImage className="add-icon" />
                </a>
                <small>Click to select image</small>
                <input
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    onChange={handleImgChange}
                    style={{ display: 'none' }}
                />
                <div className="img-container">
                    <img
                        src={(alreadySetAvatar && !preview) ? Domain + alreadySetAvatar : preview || 'https://www.signivis.com/img/custom/avatars/member-avatar-01.png'}
                        alt="avatar"
                        width="200"
                        height="200"
                    />
                </div>
            </main>
        </>
    );
};

const CropperModal = ({ src, modalOpen, setModalOpen, setPreview, setEditedAvatar }) => {
    const [slideValue, setSlideValue] = useState(10);
    const cropRef = useRef(null);

    const handleSave = async () => {
        if (cropRef.current) {
            const canvas = cropRef.current.getImage().toDataURL('image/jpeg', 0.4); // Convert to JPEG with 40% quality
            const result = await fetch(canvas);
            const blob = await result.blob();
            const file = new File([blob], "profilePhoto.jpg", { type: "image/jpeg" });
            setEditedAvatar(file);
            setPreview(URL.createObjectURL(blob));
            setModalOpen(false);
        }
    };

    const handleCancel = () => {
        setPreview(null);
        setModalOpen(false);
    };

    return (
        modalOpen && (
            <div className="modal-overlay">
                <div className="modal-content">
                    <AvatarEditor
                        ref={cropRef}
                        image={src}
                        style={{ width: '100%', height: '100%' }}
                        border={50}
                        borderRadius={150}
                        color={[0, 0, 0, 0.72]}
                        scale={slideValue / 10}
                        rotate={0}
                    />
                    <input
                        type="range"
                        min={10}
                        max={50}
                        value={slideValue}
                        onChange={(e) => setSlideValue(e.target.value)}
                        className="slider"
                    />
                    <div className="button-group">
                        <div onClick={handleCancel} className="button cancel-button">
                            Cancel
                        </div>
                        <div onClick={handleSave} className="button save-button">
                            Save
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default Cropper;
