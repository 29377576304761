import React from 'react';
import { Domain } from '../ApiConfig';

export default function GalleryCard({ heading, photos }) {
    return (
        // <div className="flex flex-wrap justify-center">
        <>
            {photos.map((img, index) => {
                return (
                    <div
                        key={index}
                        className="shadow-lg w-full text-center m-5 bg-white rounded-md md:w-1/3 lg:w-1/3 xl:w-1/4"
                    >
                        <div className="overflow-hidden rounded-t-md">
                            <img
                                className="w-full h-auto"
                                src={Domain + img}

                                alt={`Gallery image ${index + 1}`}
                                loading='lazy'
                            />
                        </div>
                        <p className="p-2">{heading}</p>
                    </div>
                );
            })}
        </>
        // </div>
    );
}
