import React, { useEffect, useState } from 'react';

const SearchDropDown = ({ options, setOptions, setItem, getValues, search, id, label, alreadySelected, style, register, dataFeildName }) => {
    const [searchTerm, setSearchTerm] = useState(alreadySelected?.name?.toString() || "");
    const [isFocused, setIsFocused] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const filteredData = options?.filter(item =>
            item[search].toLowerCase().includes(searchTerm + "".toLowerCase())
        );
        setFilteredData(filteredData);
    }, [searchTerm]);
    const handleInputChange = e => {
        setSearchTerm(e.target.value);
    };
    const handleInputFocus = () => {
        setIsFocused(true);
    };
    const setValue = async (item, dataFeildName) => {
        setSearchTerm(item[search]);
        const fieldDetail = {
            name: item[search],
            id: item[id]
        };
        // setVatue({ ...item, item: search });
        // Call the register function from props to set the form data
        if (register) {
            register(dataFeildName, { value: fieldDetail });
        }


        setItem({ item: search, id: item[id] })
        setIsFocused(false);
        let fieldValue;
        if (register) {
            fieldValue = getValues(dataFeildName);
        }

    };

    const clearSearch = () => {
        setSearchTerm("");
        setIsFocused(false);
    };

    return (
        <div className="relative">
            <label htmlFor={label} className={`block capitalize text-sm font-extrabold leading-6 text-gray-900 ${style}`}>
                {label}
            </label>
            <input
                type="text"
                id={label}
                name={dataFeildName}
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                placeholder="Search..."
                autoComplete="off"
                className="block w-full h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pt-6 pr-3">
                {isFocused ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        onClick={clearSearch}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                )}
            </div>
            {
                isFocused && (
                    <div className="absolute h-52 overflow-y-auto z-10 top-full w-full bg-white border border-gray-300 rounded-b-md shadow-lg">
                        {filteredData?.map((item, index) => (
                            <div
                                key={index}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            >
                                <p onClick={() => setValue(item, dataFeildName)}> {item[search]}</p>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    );
};

export default SearchDropDown;
