import React, { useState } from 'react'
import FormHeading from './FormHeading'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { Input, Textarea } from '../FormsInputes.js';
import { HeaderBoarder } from '../Components/index.js';
import { addAchivement, addVaishyaNews } from '../service/Services.js';

export default function NewsForm() {
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)
    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm();

    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };

    const handleFileChange = (fimeName, e) => {
        const file = e.target.files[0]; // Get the first file from the input
        setValue(fimeName, file);
    };

    const submit = async (data) => {
        try {
            const requiredFields = ['newsHeading', 'message', 'newsPhoto'];
            const hasMissingFields = requiredFields.some(field => !data[field]);
            if (hasMissingFields) {
                throw new Error("All Star fields are required");
            }
            setLoading(true);

            const formData = new FormData();

            formData.append('newsHeading', data.newsHeading);
            formData.append('message', data.message);
            formData.append('newsPhoto', data.newsPhoto);
            // Call addNews API
            const isNewsAdded = await addVaishyaNews(formData);
            // Check if news added successfully
            if (isNewsAdded?.success) {
                Swal.fire({
                    title: isNewsAdded.success,
                    text: isNewsAdded.message,
                    icon: 'success',
                });
                reset()
            }
        } catch (error) {
            console.error(error);
            setError(error.message);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
            });
        } finally {
            setLoading(false);

        }
    };

    return (
        <>
            <HeaderBoarder heading={"उपलब्धियां"} />
            <div className='w-100  md:px-10 '>
                <form onSubmit={handleSubmit(submit)} className='border-[1px] border-gray-900/10 p-4'>
                    {/* <FormHeading heading={'उपलब्धियां'} /> */}
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">

                        {/* achievement heading */}
                        <div className="sm:col-span-3">
                            <Input
                                label="News heading "
                                placeholder="News heading "
                                type="text"
                                required="required"

                                {...register("newsHeading", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: "कृपया 200 अक्षरों से कम भरें"
                                    }

                                })}
                            />
                            {errors.newsHeading && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.newsHeading.message}</p>}
                        </div>





                        {/* profile Photo--2 */}
                        <div className="sm:col-span-3">
                            <Input
                                label="News photo / फोटो"
                                placeholder="news photo"
                                type="file"
                                required="required"
                                onChange={(e) => handleFileChange("newsPhoto", e)}
                            />
                            {errors.newsPhoto && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.newsPhoto.message}</p>}
                        </div>

                        <div className="sm:col-span-3">
                            <Input
                                label="Date / एन्ट्री तारीख"
                                placeholder="एन्ट्री तारीख"
                                type="text"
                                required="required"
                                value={new Date().toLocaleString()}
                                disabled

                            />
                            {/* {errors.donationDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.donationDate.message}</p>} */}
                        </div>

                        <div className="col-span-full mx-2 ">
                            <Textarea
                                label="message / संदेश"
                                placeholder="message"
                                type="text"
                                {...register("message", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    // value: `${formData?.permanentAddress}`,
                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 20 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 2000,
                                        message: "कृपया 2000 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("message", e)}
                            />
                            {errors.message && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.message.message}</p>}

                        </div>

                    </div>
                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                        {!isLoading ? <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button> : <button
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sending...
                        </button>}
                    </div>

                </form>
            </div>
        </>
    )
}
