import axios from "axios";
// import * as siteConfig from "../config/config";
//const baseURL = "http://127.0.0.1:8000"
// const baseURL = "https://tmsdb.transduniya.com/"
// const baseURL = "https://dealerdata.companees.com/"
// const config = {
//     apiBaseURL: `${baseURL}`,
//     staticBaseURL: `${baseURL}`,
//     apiTimeout: 500000
// }
// export default config;
// https://social-app-backend-xi.vercel.app/api/v1
// "http://localhost:8000/api/v1",
// const accessToken = token();
// const accessToken = sessionStorage.getItem('access_token')
// console.log(accessToken) // Replace with your token retrieval logic
// const authorizationHeader = `Bearer ${accessToken}`;
// requestOptions.headers['Authorization'] = authorizationHeader

const API = axios.create({
    baseURL: "https://vaishya.transdunia.com/api/v1",
    // baseURL: "http://localhost:4000/api/v1",
    timeout: 500000,
    withCredentials: true,
    credentials: true,
})


API.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        Promise.reject(error)
    }
);
API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error.response.data)
    }
);


export default API;