import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { memberScanByQr } from '../../service/Services';

export default function SamajEntryQrScan() {
    const [scanedProfile, setProfile] = useState(null)
    const [loading, setLoading] = useState(false)

    const { matCode } = useParams()

    useEffect(() => {
        const fetchScannedMember = async () => {
            try {
                setLoading(true)
                const scannedMember = await memberScanByQr({ matCode });
                console.log("scannedMember", scannedMember)
                if (scannedMember?.success) {
                    setProfile(scannedMember?.data)
                }
                // Do something with the scannedMember, e.g., update state
            } catch (error) {
                console.error('Error fetching scanned member:', error);
                // Handle error, e.g., show an error message
            } finally {
                setLoading(false)
            }
        };

        fetchScannedMember();
    }, [matCode]);

    console.log('matCode', matCode)
    return (
        <div>samajEntryQrScan</div>
    )
}
