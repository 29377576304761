import React, { useState } from 'react';
import Logo from "../assets/dashbordBtn/aanshilogo.png"
import { Link } from 'react-router-dom';

export default function Footer() {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>

            <ContactUs isOpen={isOpen} onClose={toggleModal} />
            <footer className="secondaryColor mt-10  flex flex-wrap justify-center items-center text-black py-4 ">
                <div className=' w-full '>
                    <div className="container mx-auto px-4 flex items-center justify-center">
                        <p className='px-2 text-gray-800  font-bold'>Powered By :</p>
                        <img className='w-16' src={Logo} alt="Company Logo" />
                    </div>
                    <div className=' min-w-[300px] font-semibold text-gray-700  flex items-center justify-around  test-center '>
                        {/* <Link to={'/Privacypolicy'} >Privacy Policy</Link> */}
                        <a href='/privacypolicy.html'>Privacy Policy</a>
                        <button onClick={toggleModal} >Contact Us</button>
                    </div>
                </div>
            </footer>
        </>
    );
}



const ContactUs = ({ isOpen, onClose }) => {
    return (
        <>
            {isOpen && (
                <div className="fixed z-50 inset-0 overflow-y-auto flex items-center justify-center">
                    <div className="fixed inset-0 transition-opacity" onClick={onClose}>
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <div className="relative bg-white p-8 rounded-md max-w-md">
                        <div className="absolute top-0 right-0 p-2">
                            <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
                                <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                        </div>
                        <div className=' text-center flex flex-col items-center text-xl font-bold mb-3'>
                            <img className=' w-16 h-16' src={Logo} />
                            <h2 className="text-xl font-bold mb-4">Aanshi Solutions</h2>
                            <p className="mb-4">Vishal H Porwal</p>
                            <p className="mb-4">📞 9827072993</p>
                            <div className="flex justify-end">
                                <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={onClose}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};



