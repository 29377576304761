import React, { useState } from 'react';

const DateInputCriteria = ({ search, id, label, setOptions, className, style, ...props }) => {
    const [selectedDate, setSelectedDate] = useState("");

    // Function to format the date for input type="datetime-local" (YYYY-MM-DDTHH:MM)
    const formatDateForInput = (date) => {
        const d = new Date(date);
        return d.toISOString().slice(0, 16); // Get date and time without seconds and milliseconds
    };

    const handleDateChange = (e) => {
        const inputValue = e.target.value;

        if (!inputValue) {
            // If the user clears the date, reset the state and search criteria
            setSelectedDate(""); // Clear the state
            setOptions((prev) => ({
                ...prev,
                [search]: null, // Or you can remove the date from the criteria if needed
            }));
        } else {
            const date = new Date(inputValue).toISOString(); // Convert to ISO format
            setSelectedDate(inputValue); // Keep the datetime-local format
            // Set the options with the formatted ISO date
            setOptions((prev) => ({
                ...prev,
                [search]: date, // Save the full ISO date to the search criteria
            }));
        }
    };


    return (
        <div className={` w-full`}>
            {/* <label className={style} htmlFor={id}>{label}</label> */}
            <label htmlFor={id} className={` capitalize block  text-sm font-extrabold  leading-6 text-gray-900}`}>
                {label}
            </label>
            <input
                type="date"
                id={id}
                value={selectedDate} // Keep the datetime-local format
                onChange={handleDateChange}
                {...props}
                className={` ${className}`} />
        </div>
    );
};

export default DateInputCriteria;
